import React from 'react'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import ArrowSvg from '../../assets/images/arrow.svg'

const PagerStyled = styled.div`
  position: relative;
  cursor: pointer;
  margin: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    border-radius: 100%;
    left: 0;
    top: 0;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background-color: white;
    transition: transform 0.6s ${props => props.theme.eases.out};
    border: 1px solid ${props => props.theme.colors.green};

    ${up('sm')} {
     ${props => !props.border && `
        width: 100%;
        height: 100%;
        border: none;
     `}
    }
  }

  &:hover {
    &::after { transform: scale(0.9); }

    span {
      opacity: 0;
      transform: scaleX(1.8) translateX(-45px);

      &:first-child {
        opacity: 1;
        transform: scaleX(1) translateX(-45px);
      }
    }
  }

  span {
    position: relative;
    z-index: 1;
    line-height: 0;
    transform: scaleX(1);
    transition:
      opacity 0.6s ${props => props.theme.eases.inOut},
      transform 0.6s ${props => props.theme.eases.inOut};

    &:first-child {
      position: absolute;
      left: 60px;
      opacity: 0;
      height: 12px;
      top: calc(50% - 6px);
      transform: scaleX(1.8);
      transition:
        opacity 0.6s ${props => props.theme.eases.inOut},
        transform 0.6s ${props => props.theme.eases.inOut};
    }
  }

  ${props => props.right && `transform: rotate(180deg);`}
`

const Pager = React.forwardRef((props, ref) => (
  <PagerStyled  ref={ref} {...props}>
    <span><ArrowSvg/></span>
    <span><ArrowSvg/></span>
  </PagerStyled>
))

export default Pager