import React, { useEffect, useRef } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { Container, Row, Col } from 'react-grid-system'
import Text from '../text'
import ClassicContent from '../classic-content'
import SlideManager from 'slidemanager'
import gsap from 'gsap'
import Pager from '../pager'

const Section = styled.section`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 25px 0;

  ${up('md')} { margin: 50px 0; }
`

const Title = styled(Text)`
  margin-bottom: 30px;
  color: ${props => props.theme.colors.yellow};

  ${up('sm')} { text-align: center; }
`

const SlidesWrapper = styled.div`
  position: relative;
  overflow: hidden;
  box-sizing: content-box;
  width: 100%;

  ${up('sm')} { padding: 100px 0; }

  ${up('xxl')} { padding: 180px 0; }
`

const SlidesImagesWrapper = styled.div`
  position: relative;
  height: 200px;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;

  ${up('sm')} {
    position: absolute;
    height: 100%;
  }
`

const SlidesContentWrapper = styled.div`
  position: relative;
  z-index: 2;
`

const SlideContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  padding: 0 15px;
  width: 100%;

  ${props => !props.show && `
    opacity: 0;
    pointer-events: none;
  `};

  ${up('sm')} {
    margin-left: 8.33vw;
    padding: 50px 4.16vw;
    width: 83.33vw;
  }

  ${up('lg')} { width: 45.8vw; }

  ${up('xl')} { width: 37.5vw; }

  ${up('xxl')} { padding: 80px 4.16vw; }
`

const SlideImage = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  
  ${props => !props.show && `
    opacity: 0;
    pointer-events: none;
  `};
`

const Pagers = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-bottom: 30px;
  display: ${props => props.device === `mobile` ? `flex`: `none` };

  ${up('sm')} {
    margin-left: 0;
    margin-bottom: 0;
    margin-top: -70px;
    width: 100%;
    display: ${props => props.device === `mobile` ? `none`: `flex` };
  }

  ${up('lg')} {
    width: auto;
    margin-top: 0;
    position: absolute;
    top: calc(50% + 30px);
    right: 8.33vw;
  }
`

const BannerSlider = (props) => {
  let slider = null
  let padding = 0
  let maxHeight = null

  const pagerLeft = useRef(null)
  const pagerRight = useRef(null)
  const pagerLeft2 = useRef(null)
  const pagerRight2 = useRef(null)
  const slidesWrapper = useRef(null)
  const slidesContents = useRef([props.slides.length])
  const slidesImages = useRef([props.slides.length])

  const onChangeSlide = (event) => {
    if (window.innerWidth < 768) {
      gsap.to([slidesImages.current[event.previous], slidesContents.current[event.previous]], {
        alpha: 1,
        x: event.direction * -window.innerWidth,
        duration: 0.5,
        ease: 'power2.out'
      })

      gsap.fromTo([slidesImages.current[event.current], slidesContents.current[event.current]], {
        x: event.direction * window.innerWidth,
      }, {
        alpha: 1,
        x: 0,
        duration: 0.5,
        ease: 'power2.out',
        onComplete: slider.done.bind(slider)
      })
    } else {
      gsap.to(slidesImages.current[event.previous], {
        alpha: 0,
        scale: event.direction === -1 ? 1.1 : 1,
        duration: 0.5,
        ease: 'power2.out'
      })
  
      gsap.fromTo(slidesImages.current[event.current], {
        alpha: 0,
        scale: event.direction === -1 ? 1 : 1.1
      }, {
        scale: 1.05,
        alpha: 1,
        duration: 0.5,
        ease: 'power2.out'
      })
  
      gsap.to(slidesContents.current[event.previous], {
        rotate: event.direction * -20,
        y: event.direction * -(slidesWrapper.current.offsetHeight - padding + 150),
        x: -200,
        duration: 0.5,
        ease: 'power2.out'
      })
  
      gsap.fromTo(slidesContents.current[event.current], {
        alpha: 1,
        x: 200,
        rotate:  -event.direction * 20,
        y: event.direction * (slidesWrapper.current.offsetHeight - padding + 150),
      }, {
        rotate: 0,
        y: 0,
        x: 0,
        duration: 0.5,
        ease: 'power2.out',
        onComplete: slider.done.bind(slider)
      })
    }
  }

  const createSlider = () => {
    slider = new SlideManager({
      length: slidesContents.current.length,
      loop: true,
      auto: false,
      interval: 5,
      callback: onChangeSlide
    })
  }

  const setSizes = () => {
    requestAnimationFrame(() => {
      maxHeight = 0

      slidesContents.current.map((el) => { maxHeight = maxHeight > el.offsetHeight ? maxHeight : el.offsetHeight })

      if (window.innerWidth < 768) maxHeight += 260

      slidesWrapper.current.style.height = `${maxHeight}px`

      padding = window.getComputedStyle(slidesWrapper.current).paddingTop
      padding = Number(padding.substring(0, padding.length - 2))
    })
  } 

  useEffect(() => {
    setSizes()
    createSlider()

    window.addEventListener('resize', setSizes)
    pagerLeft.current && pagerLeft.current.addEventListener('click', slider.prev.bind(slider))
    pagerRight.current && pagerRight.current.addEventListener('click', slider.next.bind(slider))
    pagerLeft2.current && pagerLeft2.current.addEventListener('click', slider.prev.bind(slider))
    pagerRight2.current && pagerRight2.current.addEventListener('click', slider.next.bind(slider))

    return () => { window.removeEventListener('resize', setSizes) }
  }, [])

  return (
    <Section data-scroll-section>
      <Container fluid>
        <Row>
          <Col lg={20} offset={{lg: 2}}>
            <Title tag='div' size='72'>{props.title}</Title>
          </Col>
          <Pagers device="mobile">
            <Pager ref={pagerLeft} left></Pager>
            <Pager ref={pagerRight} right></Pager>
          </Pagers>
          <SlidesWrapper ref={slidesWrapper}>
            <SlidesImagesWrapper>
              {props.slides && props.slides.map((slide, index) => (<SlideImage ref={el => slidesImages.current[index] = el} show={index === 0} key={`image-${index}`}>{slide.image && <Img fixed={ slide.image.sourceUrlSharp.childImageSharp.fixed } alt={ slide.image.altText } />}</SlideImage>))}
            </SlidesImagesWrapper>
            <SlidesContentWrapper>
              {props.slides && props.slides.map((slide, index) => (<SlideContent ref={el => slidesContents.current[index] = el} show={index === 0} key={`content-${index}`}><ClassicContent props={slide}/></SlideContent>))}
            </SlidesContentWrapper>
          </SlidesWrapper>
          <Pagers device="desktop">
            <Pager ref={pagerLeft2} left></Pager>
            <Pager ref={pagerRight2} right></Pager>
          </Pagers>
        </Row>
      </Container>
    </Section>
  )
}

export default BannerSlider
