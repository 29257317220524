import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import Img from 'gatsby-image'
import { checkMobile } from '../../helpers'
import BannerButton from '../banner-button'

const Section = styled.section`
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin-bottom: 25px;

  ${up('md')} { margin-bottom: 70px; }
`

const Title = styled.div`
  position: relative;
  text-align: center;
  display: block;
  font-family: ${props => props.theme.fonts.headings};
  font-weight: 900;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 11.1vw;
  line-height: 11.1vw;

  ${up('lg')} {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @supports (-webkit-text-stroke: 2px white) {
    color: transparent;
    -webkit-text-stroke: 2px white;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: white;
    transform: none;
  }

  &::after { top: 100%; }
`

const Subtitle = styled.h1`
  text-align: center;
  display: block;
  color: white;
  font-size: 4.6vw;

  a {
    position: relative;
    color: white;
    overflow: hidden;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      border-radius: 1px;
      background-color: ${props => props.theme.colors.yellow};
      height: 2px;

      ${up('sm')} {
        height: 6px;
        border-radius: 2px;
      }
    }

    &::before {
      transform-origin: right center;
      transform: scaleX(1);
      transition: transform 0.8s ${props => props.theme.eases.out} 0.2s;
    }

    &::after {
      transform-origin: left center;
      transform: scaleX(0);
      transition: transform 0.8s ${props => props.theme.eases.out};
    }

    &:hover {
      &::before {
        transform: scaleX(0);
        transition: transform 0.6s ${props => props.theme.eases.out};
        }
      &::after {
        transform: scaleX(1);
        transition: transform 0.8s ${props => props.theme.eases.out} 0.2s;
        }
    }
  }
`

const VideoWrapper = styled.div`
  position: relative;
  pointer-events: none;
  width: 100%;
  height: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.25;
    background-color: black;
  }

  iframe {
    width: ${props => props.iframeWidth}px;
    height: ${props => props.iframeHeight}px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const ContentWrapper = styled.div`
  position: absolute;
  left: 4.16vw;
  width: 91.66%;
  top: 50%;
  transform: translateY(-50%);
`

const Image = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform: scale(${props => props.alpha ? `1` : `1.05` });
  opacity: ${props => props.alpha};
  transition:
    opacity 1s ${props => props.theme.eases.out},
    transform 1s ${props => props.theme.eases.out};

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    backface-visibility: hidden;
  }
`

const parseYoutubeUrl = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)

  return (match&&match[7].length === 11)? match[7] : false
}

const HomeCover = (props) => {
  const isMobile = checkMobile()
  const videoUrl = parseYoutubeUrl(props.video)

  const subtitle = useRef(null)
  const image1 = useRef(null)
  const image2 = useRef(null)

  const [image1Alpha, setImage1Alpha] = useState(0)
  const [image2Alpha, setImage2Alpha] = useState(0)
  const [iframeWidth, setIframeWidth] = useState(0)
  const [iframeHeight, setIframeHeight] = useState(0)

  let videoActive = true
  let player = null

  const onYouTubeIframeAPIReady = () => {
    if (isMobile) return

    player = new window.YT.Player('iframe-cover', {
      videoId: videoUrl,
      playerVars: {
        'controls': 0,
        'hd': 1,
        'autoplay': 1,
        'loop': 1,
        'rel': 0,
        'background': 0,
        'mute': 1,
        'autohide': 1,
        'autopause': 0,
        'disablekb': 1,
        'modestbranding': 0,
        'showinfo': 0,
        'fs': 0,
        'iv_load_policy': 3,
        'start': 5
      }
    })
  }

  const onLinkEnter = (index) => {
    if (isMobile) return

    player && player.pauseVideo && player.pauseVideo()
    videoActive = false

    if (index === 0) setImage1Alpha(1)
    if (index === 1) setImage2Alpha(1)
  }

  const onLinkLeave = (index) => {
    if (isMobile) return

    player && player.playVideo && player.playVideo()
    videoActive = true

    if (index === 0) setImage1Alpha(0)
    if (index === 1) setImage2Alpha(0)
  }

  const checkScroll = (e) => {
    if (isMobile) return

    if (e.detail.y > window.innerHeight && videoActive) {
      player && player.pauseVideo && player.pauseVideo()
      videoActive = false
    } else if (e.detail.y <= window.innerHeight && !videoActive) {
      player && player.playVideo && player.playVideo()
      videoActive = true
    }
  }

  const setIframeSizes = () => {
    if (isMobile) return

    if (window.innerWidth < window.innerHeight) {
      setIframeWidth(window.innerHeight * (16 / 9))
      setIframeHeight(window.innerHeight)
    } else if (window.innerWidth < window.innerHeight * (16 / 9)) {
      setIframeWidth(window.innerHeight * (16 / 9))
      setIframeHeight(window.innerHeight)
    } else {
      setIframeWidth(window.innerWidth)
      setIframeHeight(window.innerWidth * (9 / 16))
    }
  }

  const loadYoutubeApi = () => {
    if (isMobile) return

    if (!window.YT) {
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady

      const tag = document.createElement('script')
      const firstScriptTag = document.getElementsByTagName('script')[0]

      tag.src = 'https://www.youtube.com/iframe_api'
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', setIframeSizes)
    window.addEventListener('customScrollEvent', checkScroll)
    subtitle.current.querySelectorAll('a').forEach((element, index) => {
      element.addEventListener('mouseenter', onLinkEnter.bind(this, index))
      element.addEventListener('mouseleave', onLinkLeave.bind(this, index))
    })

    setIframeSizes()

    if (window.YT) onYouTubeIframeAPIReady()
    else loadYoutubeApi()

    return () => {
      window.removeEventListener('resize', setIframeSizes)
      window.removeEventListener('customScrollEvent', checkScroll)

      subtitle.current.querySelectorAll('a').forEach((element, index) => {
        element.removeEventListener('mouseenter', onLinkEnter.bind(this, index))
        element.removeEventListener('mouseleave', onLinkLeave.bind(this, index))
      })
    }
  }, [])

  return (
    <Section data-scroll-section>
      <VideoWrapper iframeWidth={iframeWidth} iframeHeight={iframeHeight} data-scroll data-scroll-speed="-1">
        <div id="iframe-cover"></div>
        <Image alpha={isMobile ? 1 : image1Alpha} ref={image1}>{props.image1 && <Img fixed={ props.image1.sourceUrlSharp.childImageSharp.fixed } alt={ props.image1.altText } />}</Image>
        <Image alpha={image2Alpha} ref={image2}>{props.image2 && <Img fixed={ props.image2.sourceUrlSharp.childImageSharp.fixed } alt={ props.image2.altText } />}</Image>
      </VideoWrapper>
      <ContentWrapper>
        <Title>{props.title}</Title>
        <Subtitle ref={subtitle} dangerouslySetInnerHTML={{__html: props.text}}/>
      </ContentWrapper>
      {props.link && <BannerButton mode='green' {...props.link}/>}
    </Section>
  )
}

export default HomeCover
