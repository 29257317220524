import React, { useRef } from 'react'
import GravityFormForm from 'gatsby-gravityforms-component'
import { useFormData } from '../hooks/useFormData'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'
import ArrowSvg from '../../assets/images/arrow-stroke.jpg'
import { Container, Row, Col } from 'react-grid-system'
import Suptitle from '../suptitle'
import VariableStyle from '../VariableStyle'

const fieldsSizes = css`
  font-size: 20px;
  line-height: 33px;

  ${up('sm')} {
    font-size: 40px;
    line-height: 53px;
  }

  ${up('lg')} {
    font-size: 45px;
    line-height: 58px;
  }

  ${up('xxl')} {
    font-size: 50px;
    line-height: 63px;
  }
`

const FormWrapper = styled.div`
  .gform_confirmation_wrapper {
    .gform_confirmation_message {
      color: ${props => props.theme.colors.green};
      font-family: ${props => props.theme.fonts.content};
      font-size: 18px;
      font-weight: normal;
    }
  }

  form {
    .validation_error { display: none; }

    .gform_body {
      .gform_fields {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;

        .gfield {
          position: relative;
          list-style-type: none;
          margin-bottom: 0 !important;
          margin-top: 0 !important;
          padding: 0;
          width: 100%;

          label {
            font-family: ${props => props.theme.fonts.content};
            font-size: 16px;
            line-height: 19px;
            color: ${props => props.theme.colors.green};
            font-weight: normal;
            width: 100%;
          }

          .ginput_container {
            margin: 0;

            &.ginput_container_select {
              width: 100%;
              background: url(${ArrowSvg}) no-repeat;
              background-position: right center;

              body.isMobile & { background: none; }
              body:not(.isMobile) & select { appearance: none; }

              ${up('sm')} { background-size: 45px 52px; }
              ${up('lg')} { background-size: 50px 57px; }
              ${up('xl')} { background-size: 56px 63px; }
            }

            input,
            textarea,
            select {
              width: 100%;
              padding: 30px 0 !important;
              background-color: transparent;
              border: 0;
              border-radius: 0;
              border-bottom: 1px solid ${props => props.theme.colors.green}4D;
              outline: none;
              ${VariableStyle.GreenText}
              ${fieldsSizes}

              &::placeholder {
                ${VariableStyle.GreenText}
                ${fieldsSizes}
              }
            }

            select {
              option {
                color: black;
              }
            }

            textarea {
              min-height: 300px;
            }
          }

          &.gravityform__field--error {
            border: 0 !important;
            background-color: transparent !important;

            .ginput_container {
              input,
              textarea {
                border: 1px solid ${props => props.errorColor} !important;
                padding: 20px 20px 20px 20px !important;
              }
            }

            .validation_message {
              font-family: ${props => props.theme.fonts.content};
              font-size: 12px;
              color: ${props => props.errorColor};
            }
          }

          &.half {
            width: 100%;

            &:nth-child(odd),
            &:nth-child(even) { padding: 0; }

            ${up('lg')} {
              width: 50%;

              &:nth-child(odd) { padding-right: 15px; }
              &:nth-child(even) { padding-left: 15px; }
            }
          }

          &.no-label,
          &.hidden-label {
            label { display: none; }
          }
        }
      }
    }

    .gform_footer {
      padding: 0;
      margin-top: 50px;
      width: 100%;
      text-align: center;
      margin-bottom: 50px;

      ${up('lg')} { margin-top: 100px; }

      .gform_button {
        padding: 60px 100px;
        font-family: ${props => props.theme.fonts.headings};
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        border-radius: 10px;
        border: none !important;
        background-color: ${props => props.theme.colors.yellow};
        color: white;
        cursor: pointer;
        outline: none;
        appearance: none;
        transition:
          color 0.4s ${props => props.theme.eases.out},
          background-color 0.4s ${props => props.theme.eases.out};

        &:hover {
          background-color: ${props => props.theme.colors.green};
          color: ${props => props.theme.colors.yellow};
        }
      }
    }
  }
`

const ErrorMessage = styled.div`
  color: ${props => props.errorColor};
  font-family: ${props => props.theme.fonts.content};
  font-size: 18px;
`

const Section = styled.section`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 25px 0;

  ${up('md')} { margin: 50px 0; }
`

const Form = (props) => {
  const data = useFormData()
  const errorMessage = useRef(null)
  const formWrapper = useRef(null)

  const handleError = ({ values, error, reset }) => {
    errorMessage.current.innerHTML = 'Une erreur s’est produite lors de votre envoi. Les erreurs ont été mises en surbrillance plus bas.'
    window.scroll && window.scroll.scrollTo(errorMessage.current, -100)
  }

  const handleSuccess = ({ values, reset, confirmations }) => {
    if (window.scroll) {
      window.scroll.scrollTo(formWrapper.current, -100)
      window.scroll.update()
    }
  }

  return (
    <Section data-scroll-section>
      <Container fluid>
        <Row>
          <Col lg={20} offset={{ lg: 2 }}>
            <Suptitle>{props.suptitle}</Suptitle>
          </Col>
          <Col lg={20} offset={{ lg: 2 }}>
            <FormWrapper ref={formWrapper} errorColor="red">
              <ErrorMessage ref={errorMessage} errorColor="red" />
              <GravityFormForm
                id={props.formId}
                formData={data}
                lambda={process.env.GATSBY_LAMBDA_ENDPOINT}
                successCallback={handleSuccess}
                errorCallback={handleError}
              />
            </FormWrapper>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Form