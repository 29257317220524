import React from 'react'
import styled from 'styled-components'

const CoreHeadingWrapper = styled.div`
  h2 {
    font-family: ${props =>props.theme.fonts.headings};
    font-size: 25px;
    margin: 30px 0;
    color: black;
  }

  h3 {
    font-family: ${props =>props.theme.fonts.headings};
    font-size: 22px;
    margin: 20px 0;
    color: black;
  }

  h4 {
    font-family: ${props =>props.theme.fonts.headings};
    font-size: 20px;
    margin: 15px 0;
    color: black;
  }
`

const CoreHeading = (data) => {

  return (
    <CoreHeadingWrapper>
      <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
    </CoreHeadingWrapper>
  )
}

export default CoreHeading