import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { Container, Row, Col } from 'react-grid-system'
import ClassicContent from '../classic-content'

const Section = styled.section`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 25px 0;
  overflow: hidden;

  ${up('lg')} { margin: 50px 0; }
`

const Image = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 30px;

  ${up('sm')} { margin-bottom: 0; }
  
  ${props => props.contain && `display: none;`}

  ${up('sm')} { height: 350px; }

  ${up('lg')} {
    ${props => props.contain ? `
      display: block;
      height: 100vh;
      width: 900px;
      position: absolute;
      right: -40vw;
      top: 50%;
      transform: translateY(-50%);
    ` : `
      position: absolute;
      left: 15px;
      top: 0;
      width: calc(100% - 30px);
      height: 100%;
    `};
  }

  ${up('xl')} {
    ${props => props.contain && `
      position: relative;
      transform: none;
      right: 0;
      top: 0;
      height: 100vh;
      width: 1000px;
    `};
  }

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: ${props => props.contain ? `contain` : `cover`} !important;
    object-position: ${props => props.contain ? `right` : `center`} !important;
  }
`

const ImageContent = (data) => {
  return (
    <Section data-scroll-section>
      <Container fluid>
          {data.fullSize ?
            <Row align='center'>
              <ContentData {...data}/>
              <ImageData {...data} />
            </Row>
          :
            <Row>
              <ImageData {...data} />
              <ContentData {...data}/>
            </Row>
          }
      </Container>
    </Section>
  )
}

const ImageData = (data) => {
  const sizeImage = data.fullSize ? 12 : 9
  const offsetImage = data.fullSize ? { lg: 1 } : { lg: 2 }

  return (
    <Col lg={sizeImage} offset={offsetImage}>
      <Image contain={data.fullSize === true}>
        {data.image && <Img fixed={ data.fullSize ? data.image.sourceUrlSharp.childImageSharp.containImage : data.image.sourceUrlSharp.childImageSharp.fixed } alt={ data.image.altText } />}
      </Image>
    </Col>
  )
}

const ContentData = (data) => {
  return (
    <Col lg={9} offset={{ lg: 2 }}>
      <ClassicContent props={data}/>
    </Col>
  )
}

export default ImageContent
