import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import Text from '../text'

const Section = styled.section`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 25px 0;

  ${up('md')} { margin: 50px 0; }
`

const Card = styled.div`
  margin-top: 50px;

  ${up('md')} { margin-top: 100px; }
`

const CardImage = styled.div`
  position: relative;
  z-index: 2;
  height: 80px;
  width: 80px;

  img {
    object-fit: contain !important;
  }
`

const CardTitle = styled(Text)`
  margin-top: 30px;
  margin-bottom: 0;
  color: ${props => props.theme.colors.green};
`

const CardContent = styled(Text)`
  margin-top: 20px;
`

const GridContent = (props) => {
  return (
    <Section data-scroll-section>
      <Container fluid>
        <Row>
          {props.contents && props.contents.map((content, index) => {
            let offset = { lg: 1 }

            if ([0, 3, 6].indexOf(index) !== -1) offset = { lg: 2 }

            return (
              <Col sm={12} lg={6} offset={offset} key={index}>
                <Card>
                  {content.image && <CardImage><Img fixed={ content.image.sourceUrlSharp.childImageSharp.fixed } alt={ content.image.altText } /></CardImage>}
                  <CardTitle tag='h3' size='40'>{content.title}</CardTitle>
                  <CardContent tag='div' size='14' dangerouslySetInnerHTML={{__html: content.content}}/>
                </Card>
              </Col>
            )
          })}
        </Row>
      </Container>
    </Section>
  )
}

export default GridContent