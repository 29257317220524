import React from 'react'
import styled from 'styled-components'
import { useRelative } from '../hooks/useRelative'
import { Container, Row, Col } from 'react-grid-system'
import ClassicContent from '../classic-content'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Text from '../text'
import { up } from 'styled-breakpoints'

const Section = styled.section`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 25px 0;

  ${up('md')} { margin: 50px 0; }
`

const ServiceWrapper = styled(Link)`
  display: inline-block;
  position: relative;
  width: 100%;
  white-space: nowrap;

  &:nth-child(1) { margin-top: 0; }
  
  font-family: ${props => props.theme.fonts.headings};
  font-style: normal;
  font-weight: 900;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.green};
    transform: none;

    ${up('lg')} {
      left: 8.33vw;
      width: 83.34%;
    }
  }

  &::after {
    top: 100%;
  }
`

const ServiceText = styled(Text)`
  position: relative;
  -webkit-font-smoothing: antialiased;
  padding: 5px 0;

  ${up('sm')} { padding: 15px 0; }
  ${up('lg')} { padding: 30px 0 30px 8.33vw; }

  color: ${props => props.theme.colors.green};
  body:not(.isMobile) & {
    @supports (-webkit-text-stroke: 1.5px ${props => props.theme.colors.green}) {
      color: transparent;
      -webkit-text-stroke: 1.5px ${props => props.theme.colors.green};
    }
  }

  &::after {
    opacity: 0;
    position: absolute;
    will-change: transform;
    animation: moveRight 2s infinite linear;
    animation-play-state: paused;
    left: 0;
    top: 50%;
    content: "${props => props.text} ${props => props.text} ${props => props.text} ${props => props.text} ${props => props.text} ${props => props.text} ${props => props.text} ${props => props.text}";
    color: ${props => props.theme.colors.green}; 
  }

  body:not(.isMobile) &:hover {
    color: white;
    @supports (-webkit-text-stroke: 1.5px white) {
      color: transparent;
      -webkit-text-stroke: 1.5px white;
    }

    &::after {
      opacity: 1;
      animation-play-state: running;
      transition: opacity 0.5s linear;
    }
  }

  @keyframes moveRight {
    0% { transform: translate(0, -50%); }
    100% { transform: translate(-12.5%, -50%); }
  }
`

const ListServices = (props) => {
  const data = useStaticQuery(graphql`
    query listServices {
      wordpress {
        services(where: {orderby: {field: MENU_ORDER, order: ASC}}) {
          nodes {
            title
            link
          }
        }
      }
    }
  `)

  const services = data.wordpress.services.nodes

  return (
    <Section data-scroll-section>
      <Container fluid>
        <Row>
          <Col lg={20} xl={9} offset={{lg: 2}}>
            <ClassicContent props={props}/>
          </Col>
          <Col lg={24} offset={{lg: 0}}>
            {services.map((el, index) => (
              <Service key={index} {...el}/>
            ))}
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

const Service = ({ title, link }) => {
	const relativeUrl = useRelative(link)

	return (
    <ServiceWrapper  to={relativeUrl}><ServiceText size="100" text={title}>{title}</ServiceText></ServiceWrapper>
	)
}

export default ListServices