import React from 'react'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { Container, Row, Col } from 'react-grid-system'
import Text from '../text'
import VariableStyle from '../VariableStyle'

const Section = styled.section`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 25px 0 50px 0;

  ${up('md')} { margin: 50px 0 100px 0; }
`

const Item = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 5px 0;

  ${up('sm')} { padding: 15px 0; }
  ${up('md')} { padding: 30px 0; }
  
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.green};
    transform: none;
  }

  &::before {
    display: ${props => props.before ? `block`: `none`};
  }

  &::after { top: 100%; }

  ${VariableStyle.GreenText}
  ${VariableStyle.StrokeText}
`

const StrokeText = (props) => {
  return (
    <Section data-scroll-section>
      <Container fluid>
        <Row>
          {props.texts && props.texts.map((item, index) => (
            <Col lg={20} offset={{ lg: 2 }} key={index}>
              <Item before={index === 0}><Text tag='span' size='100' dangerouslySetInnerHTML={{__html: item.text }}/></Item>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  )
} 

export default StrokeText