import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import Suptitle from '../suptitle'
import { Container, Row, Col } from 'react-grid-system'
import CardPost from '../card-post'
import BannerButton from '../banner-button'

const Wrapper = styled.section`
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: ${props => props.theme.colors.gray};
  padding: 25px 0 105px 0;

  ${up('sm')} { padding: 50px 0 120px 0; }
  ${up('lg')} { padding: 100px 0 180px 0; }
`

const LastNews = (props) => {
  const data = useStaticQuery(graphql`
    query lastNews {
      wordpress {
        posts(first: 3, where: {orderby: {order: DESC, field: DATE}}) {
          nodes {
            title
            link
            categories {
              nodes {
                name
                categoryId
              }
            }
            featuredImage {
              node {
                altText
                sourceUrl
                sourceUrlSharp {
                  childImageSharp {
                    preview: fixed (width: 800, height: 500, cropFocus: CENTER) {
                      base64
                      width
                      height
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const posts = data.wordpress.posts.nodes

  return (
    <Wrapper data-scroll-section>
      <Container fluid>
        <Row>
          <Col lg={20} offset={{lg: 2}}>
            <Suptitle>{props.suptitle}</Suptitle>
          </Col>
          {posts.map((post, index) => <Col lg={20} offset={{lg: 2}} key={index}><CardPost {...post} index={index} key={index}/></Col> )}
        </Row>
      </Container>
      {props.link && <BannerButton mode='gray' {...props.link}/>}
    </Wrapper>
  )
}

export default LastNews