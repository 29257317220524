import React from 'react'

/* Import Components */
import CoreHeading from './core/coreHeading'
import CoreParagraph from './core/coreParagraph'
import CoreImage from './core/coreImage'
import CoreColumns from './core/coreColumns'
import CoreList from './core/coreList'

import ImageContent from './image-content'
import HomeCover from './home-cover'
import Banner from './banner'
import KeyNumbers from './key-numbers'
import ListServices from './list-services'
import ListReferences from './list-references'
import LastNews from './last-news'
import Cover from './cover'
import GridContent from './grid-content'
import Slider from './slider'
import Contact from './contact'
import Video from './video'
import Form from './form'
import StrokeText from './stroke-text'

const components = {
  'heading': CoreHeading,
  'paragraph': CoreParagraph,
  'image': CoreImage,
  'list': CoreList,
  'columns': CoreColumns,
  'cover': Cover,
  'contact': Contact,
  'home-cover': HomeCover,
  'image-content': ImageContent,
  'banner': Banner,
  'key-numbers': KeyNumbers,
  'list-services': ListServices,
  'list-references': ListReferences,
  'last-news': LastNews,
  'grid-content': GridContent,
  'slider': Slider,
  'video': Video,
  'form': Form,
  'stroke-text': StrokeText
}

const isEmpty = obj => {
  return Object.entries(obj).length === 0 && obj.constructor === Object 
}

const convertAcfProps = component => {
  Object.keys(component).forEach(key => {
    if (key.includes('Fields')) {
      component.data = component[key]
      delete component[key]
    }
  })

  return component
}

const sanitizeName = component => {
  if (!component.name) {
    console.error('Please add the \'name\' props in your GraphQL query', component)

    return
  }
  
  let newName = component.name
  newName = newName.replace('core/', '')
  newName = newName.replace('acf/', '')
  
  return newName
}

const ComponentParser = props => {
  let { content } = props
  let { fromPost } = props

  if (!content) return null
  
  content = content.filter(component => component.name !== null)

  if (content && content.length > 0) {
    const pageComponents = content.map((component, index) => {
      if (isEmpty(component)) return null
      if (!component) return null
      let isCore = false

      if (component.name.includes('core')) isCore = true

      const componentName = sanitizeName(component)
      const Component = components[componentName]
      
      if (isCore) component.data = { content: component.originalContent || component.saveContent }
      else component = convertAcfProps(component)

      if (!Component) return null

      return (
        <Component
          index={index}
          key={`component-${Math.random().toString(36).substr(2, 9)}`}
          {...component.data}
          fromPost={fromPost}
        />
      )
    })

    if (pageComponents) {
      return pageComponents
    }
  }

  return null
}

export default ComponentParser