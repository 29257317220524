import { useStaticQuery, graphql } from 'gatsby'

export const useFormData = () => {
  const { allGfForm } = useStaticQuery(
    graphql`
      query WPForms {
        allGfForm {
          edges {
            node {
              ...GravityFormComponent
            }
          }
        }
      }
    `
  )
  return allGfForm
}