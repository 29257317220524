import React from 'react'
import BannerSlider from './BannerSlider'
import NumberSlider from './NumberSlider'
import CarouselSlider from './CarouselSlider'
import BlocSlider from './BlocSlider'

const Slider = (props) => {
  const parser = {
    banner: BannerSlider,
    number: NumberSlider,
    carousel: CarouselSlider,
    bloc: BlocSlider,
  }

  const SliderComponent = parser[props.type]

  return <SliderComponent {...props}/>
}

export default Slider
