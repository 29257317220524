import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Col, Hidden } from 'react-grid-system'
import Img from 'gatsby-image'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'
import CardReference from '../card-reference'
import ClassicContent from '../classic-content'

const Section = styled.section`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 25px 0;

  ${up('md')} { margin: 50px 0; }

  ${up('xxl')} { margin-bottom: -150px; }
`

const Image = styled.div`
  position: relative;
  width: 100%;
  height: 400px;

  ${up('sm')} { height: 350px; }
  ${up('lg')} { height: 500px; }
  ${up('xl')} { height: 600px; }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .gatsby-image-wrapper { height: 100%; }
`

const ListReferences = (props) => {
  const data = useStaticQuery(graphql`
    query listReferences {
      wordpress {
        references (where: {orderby: {field: MENU_ORDER, order: ASC}}) {
          nodes {
            title
            link
            types {
              nodes {
                termTaxonomyId
              }
            }
            featuredImage {
              node {
                altText
                sourceUrl
                sourceUrlSharp {
                  childImageSharp {
                    fixed (width: 700, height: 500, cropFocus: CENTER) {
                      base64
                      width
                      height
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const references = data.wordpress.references.nodes

  let cpt = -1

  return (
    <Section data-scroll-section>
      <Hidden xs sm>
        {props.image && <Image><Img fixed={ props.image.sourceUrlSharp.childImageSharp.fixed } alt={ props.image.altText } /></Image>}
      </Hidden>
      <Container fluid>
        <Row>
        <Hidden md lg xl xxl>
          <Col md={12} lg={9} offset={{lg: 2}}>
            <ClassicContent props={props}/>
          </Col>
        </Hidden>
          {references.map((el, index) => {
            const add = props.type ? el.types.nodes.filter((type) => type.termTaxonomyId === props.type.termTaxonomyId).length : true

            if (add && cpt < 2) {
              {cpt += 1}
              return (
                <Col md={12} lg={9} offset={{lg: 2}} key={index} data-scroll data-scroll-speed={1 + 0.3 * cpt}>
                  <CardReference {...el} position={cpt % 2 === 0 ? 'left' : 'right'}/>
                </Col>
              )
            }
          })}
          <Hidden xs sm>
            <Col md={12} lg={9} offset={{lg: 2}}>
              <ClassicContent props={props}/>
            </Col>
          </Hidden>

        </Row>
      </Container>
    </Section>
  )
}

export default ListReferences