import React, { useEffect, useRef } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { Container, Row, Col } from 'react-grid-system'
import Suptitle from '../suptitle'
import Text from '../text'

import SlideManager from 'slidemanager'
import Pager from '../pager'
import gsap from 'gsap'

const Pagers = styled.div`
  position: relative;
  align-items: center;
  margin-left: -5px;
  margin-bottom: 30px;
  display: ${props => props.device === `mobile` ? `flex`: `none` };

  ${up('lg')} {
    margin-bottom: 0;
    margin-left: 8.33vw;
    display: ${props => props.device === `mobile` ? `none`: `flex` };
  }
`

const Section = styled.section`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 25px 0 50px 0;

  ${up('md')} { margin: 50px 0 100px 0; }
`

const CarouselSlider = (props) => {
  let slider = null
  let sliderWidth = null
  let currentTransform = 0
  let tl = null

  const pagerLeft = useRef(false)
  const pagerRight = useRef(false)
  const pagerLeft2 = useRef(false)
  const pagerRight2 = useRef(false)
  const sliderWrapper = useRef(false)
  const slides = useRef([props.slides.length])
  const slidesContents = useRef([props.slides.length])

  const onChangeSlide = (event, data) => {
    if (tl && tl.isActive()) tl.kill()

    tl = new gsap.timeline()

    tl.addLabel('start', 0)

    sliderWidth = event.direction === 1 ? slides.current[event.previous].offsetWidth + 10 : slides.current[event.current].offsetWidth + 10

    currentTransform -= sliderWidth * event.direction

    tl.to(slidesContents.current[event.previous], {
      alpha: 0,
      duration: 0.3,
      ease: 'power2.out'
    }, 'start')

    tl.to(sliderWrapper.current, {
      x: `${currentTransform}px`,
      duration: 0.5,
      ease: 'power2.out'
    }, 'start')

    tl.fromTo(slidesContents.current[event.current], { alpha: 0 }, {
      alpha: 1,
      duration: 0.7,
      ease: 'power2.out'
    }, 'start')

    slider.done()
  }

  const createSlider = () => {
    slider = new SlideManager({
      length: props.slides.length,
      loop: false,
      auto: false,
      interval: 5,
      callback: onChangeSlide
    })
  }

  const resize = () => {
    slider.goTo(0)

    requestAnimationFrame(() => {
      tl && tl.isActive() && tl.kill()

      gsap.set(slidesContents.current, { alpha: 0 })
      gsap.set(sliderWrapper.current, { x: 0 })
      gsap.set(slidesContents.current[0], { alpha: 1 })

      currentTransform = 0
    })
  }

  useEffect(() => {
    createSlider()

    window.addEventListener('resize', resize)
    pagerLeft.current && pagerLeft.current.addEventListener('click', slider.prev.bind(slider))
    pagerRight.current && pagerRight.current.addEventListener('click', slider.next.bind(slider))
    pagerLeft2.current && pagerLeft2.current.addEventListener('click', slider.prev.bind(slider))
    pagerRight2.current && pagerRight2.current.addEventListener('click', slider.next.bind(slider))

    return () => { window.removeEventListener('resize', resize) }
  }, [])

  return (
    <Section data-scroll-section>
      <Container fluid>
        <Row>
          <Col lg={20} offset={{ lg: 2 }}><Suptitle>{props.title}</Suptitle></Col>
        </Row>
        <Pagers device="mobile">
          <Pager ref={pagerLeft} left border></Pager>
          <Pager ref={pagerRight} right border></Pager>
        </Pagers>
        <div ref={sliderWrapper}>
          <Row nowrap gutterWidth={10}>
            {props.slides && props.slides.map((slide, index) => (
              <Col
                key={index}
                sm={props.typeContent === 'image' ? 'content' : 18}
                lg={props.typeContent === 'image' ? 'content' : 10}
                offset={{ lg: index === 0 ? 2 : 0 }}
              >
                <SlideItem {...slide} typeContent={props.typeContent} index={index} slides={slides} slidesContents={slidesContents}></SlideItem>
              </Col>
            ))}
          </Row>
        </div>
        <Pagers device="desktop">
          <Pager ref={pagerLeft2} left border></Pager>
          <Pager ref={pagerRight2} right border></Pager>
        </Pagers>
      </Container>
    </Section>
  )
}

const Slide = styled.div`
  position: relative;
`

const SlideNumber = styled.div`
  pointer-events: none;
  position: absolute;
  right: 4.16vw;
  top: 250px;
  z-index: 2;
  font-size: 150px;
  line-height: 150px;
  color: ${props => props.theme.colors.green};
  transform: translateY(-50%);

  ${up('sm')} {  top: 350px; }
  ${up('md')} {
    font-size: 250px;
    line-height: 250px;
  }
  ${up('xxl')} {  top: 500px; }
`

const SlideNumberCarousel = styled(SlideNumber)`

  ${up('sm')} {  top: 350px; }
  ${up('md')} {
    font-size: 250px;
    line-height: 250px;
  }
  ${up('xl')} {  top: 550px; }
`

const SlideImage = styled.div`
  width: 100%;
  height: 250px;

  ${up('sm')} {  height: 350px; }
  ${up('xxl')} {  height: 500px; }
`

const SlideImageCarousel = styled.div`
  height: 250px;
  width: 100%;

  ${up('sm')} {
    height: 350px;
    width: ${props => props.ratio * 350}px;
  }

  ${up('xl')} {
    width: ${props => props.ratio * 550}px;
    height: 550px;
  }
`

const SlideTitle = styled(Text)`
  margin-top: 20px;
  color: ${props => props.theme.colors.green};
`

const SlideContent = styled.div`
  margin-top: 10px;
`

const SlideContentWrapper = styled.div`
  width: 75vw;
  opacity: 0;

  ${up('sm')} { width: 40vw; }
  ${up('lg')} { width: 25vw; }

  ${props => props.show && `opacity: 1;`}
`

const SlideItem = (props) => {
  return (
    <Slide ref={el => props.slides.current[props.index] = el}>
      {props.image && props.typeContent && props.typeContent === 'image' &&
        <>
          <SlideImageCarousel width={props.image.sourceUrlSharp.childImageSharp.carousel.width} ratio={`${props.image.sourceUrlSharp.childImageSharp.carousel.width / props.image.sourceUrlSharp.childImageSharp.carousel.height}`}><Img fixed={props.image.sourceUrlSharp.childImageSharp.carousel} alt={props.image.altText} /></SlideImageCarousel>
          <SlideNumberCarousel>{props.index + 1}</SlideNumberCarousel>
        </>
      }
      {props.image && props.typeContent && props.typeContent === 'image-content' &&
        <>
          <SlideImage><Img fixed={props.image.sourceUrlSharp.childImageSharp.fixed} alt={props.image.altText} /></SlideImage>
          <SlideNumber>{props.index + 1}</SlideNumber>
        </>
      }
      <SlideContentWrapper show={props.index === 0} ref={el => props.slidesContents.current[props.index] = el}>
        <SlideTitle tag='div' size='18'>{props.title}</SlideTitle>
        <SlideContent dangerouslySetInnerHTML={{ __html: props.content }} />
      </SlideContentWrapper>
    </Slide>
  )
}

export default CarouselSlider
