import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { checkMobile } from '../../helpers'
import Img from 'gatsby-image'
import gsap from 'gsap'

const Section = styled.section`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 25px 0 50px 0;

  ${up('md')} { margin: 50px 0 100px 0; }
`

const VideoWrapper = styled.div`
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.67%;

  ${props => !props.fromPost && `
    ${up('lg')} {
      padding-bottom: 0;
      height: 600px;
    }

    ${up('xl')} {
      height: 700px;
    }
  `}
`

const VideoItem = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  iframe {
    z-index: 1;
    pointer-events: none;
    width: ${props => props.iframeWidth};
    height: ${props => props.iframeHeight};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const Image = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: ${props => props.alpha};
  transition: opacity 0.4s ${props => props.theme.eases.out};

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.2;
  }
`

const cursorWidth = 120

const Cursor = styled.div`
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: ${cursorWidth}px;
  height: ${cursorWidth}px;
  border-radius: ${cursorWidth}px;
  background-color: white;
  text-transform: uppercase;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.green};
`

const parseYoutubeUrl = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)

  return (match&&match[7].length === 11)? match[7] : false
}

const Video = (props) => {
  const isMobile = checkMobile()

  let player = null
  let videoActive = false
  let videoWrapperRect = null
  let videoWrapperTop = null
  
  const image = useRef(null)
  const videoWrapper = useRef(null)
  const cursor = useRef(null)

  const videoUrl = parseYoutubeUrl(props.url)
  const [imageAlpha, setImageAlpha] = useState(1)
  const [iframeWidth, setIframeWidth] = useState(0)
  const [iframeHeight, setIframeHeight] = useState(0)

  const attributes = {}

  if (!props.fromPost) attributes['data-scroll-section'] = 'true'

  const loadYoutubeApi = () => {
    if (!window.YT) {
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady
  
      const tag = document.createElement('script')
      const firstScriptTag = document.getElementsByTagName('script')[0]
  
      tag.src = 'https://www.youtube.com/iframe_api'
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    }
  }

  const onYouTubeIframeAPIReady = () => {
    player = new window.YT.Player('iframe-cover', {
      videoId: videoUrl,
      playerVars: {
        'controls': 0,
        'hd': 1,
        'autoplay': 0,
        'loop': 1,
        'rel': 0,
        'background': 0,
        'mute': 0,
        'autohide': 1,
        'autopause': 0,
        'disablekb': 1,
        'modestbranding': 0,
        'showinfo': 0,
        'fs': 0,
        'iv_load_policy': 3
      }
    })
  }

  const setIframeSizes = (resize = false) => {
    let currentScroll = 0

    if (resize) currentScroll = window.scroll.scroll.instance.delta ? window.scroll.scroll.instance.delta.y : window.scroll.scroll.instance.scroll.y

    videoWrapperRect = videoWrapper.current.getBoundingClientRect()
    videoWrapperTop = videoWrapperRect.top + currentScroll

    if (props.fromPost) {
      setIframeWidth(`${videoWrapperRect.width}px`)
      setIframeHeight(`${videoWrapperRect.width * (9 / 16)}px`)
    } else {
      if (window.innerWidth < window.innerHeight) {
        setIframeWidth(`100%`)
        setIframeHeight(`100%`)
      } else if (window.innerWidth < window.innerHeight * (16 / 9)) {
        setIframeWidth(`${window.innerHeight * (16 / 9)}px`)
        setIframeHeight(`${window.innerHeight}px`)
      } else {
        setIframeWidth(`${window.innerWidth}px`)
        setIframeHeight(`${window.innerWidth * (9 / 16)}px`)
      }
    }
  }

  const playVideo = () => {
    if (videoActive) return

    videoActive = true
    player && player.playVideo && player.playVideo()
    setImageAlpha(0)
  }

  const pauseVideo = () => {
    if (!videoActive) return

    videoActive = false
    player && player.pauseVideo && player.pauseVideo()
    setImageAlpha(1)
  }

  const onItemClick = () => {
    videoActive ? pauseVideo() : playVideo()
  }

  const checkScroll = (e) => {
    if (!videoActive) return

    const currentScroll = isMobile ? window.pageYOffset : e.detail.y

    if (!(currentScroll + window.innerHeight > videoWrapperTop && currentScroll < videoWrapperTop + videoWrapperRect.height)) pauseVideo()
  }

  const onMouseMove = (e) => {
    if (isMobile || props.fromPost) return

    const currentScroll = window.scroll.scroll.instance.delta ? window.scroll.scroll.instance.delta.y : window.scroll.scroll.instance.scroll.y

    gsap.to(cursor.current, {
      y: e.clientY + currentScroll - videoWrapperTop - cursorWidth / 2,
      x: e.clientX - cursorWidth / 2,
      duration: 0.6,
      ease: 'power2.out'
    })
  }

  const onMouseLeave = () => {
    if (isMobile) return

    gsap.to(cursor.current, {
      y: videoWrapperRect.height / 2 - cursorWidth / 2,
      x: videoWrapperRect.width / 2 - cursorWidth / 2,
      duration: 0.6,
      ease: 'power2.out'
    })
  }

  useEffect(() => {
    setIframeSizes()
    onMouseLeave()
    addEvents()

    gsap.set(cursor.current, {
      y: videoWrapperRect.height / 2 - cursorWidth / 2,
      x: videoWrapperRect.width / 2 - cursorWidth / 2,
      duration: 0.6,
      ease: 'power2.out'
    })
  
    if (window.YT) onYouTubeIframeAPIReady()
    else loadYoutubeApi()
  
    return () => { removeEvents() }
  }, [])

  const addEvents = () => {
    window.addEventListener('resize', resize)
    videoWrapper.current.addEventListener('click', onItemClick)
    videoWrapper.current.addEventListener('mousemove', onMouseMove)
    videoWrapper.current.addEventListener('mouseleave', onMouseLeave)
    window.addEventListener('customScrollEvent', checkScroll)
    window.addEventListener('scroll', checkScroll)
  }

  const removeEvents = () => {
    window.removeEventListener('resize', resize)
    videoWrapper.current.removeEventListener('click', onItemClick)
    videoWrapper.current.removeEventListener('mousemove', onMouseMove)
    videoWrapper.current.removeEventListener('mouseleave', onMouseLeave)
    window.removeEventListener('customScrollEvent', checkScroll)
    window.removeEventListener('scroll', checkScroll)
  }

  const resize = () => {
    setTimeout(() => {
      setIframeSizes(true)
      isMobile ? 
      gsap.set(cursor.current, {
        y: videoWrapperRect.height / 2 - cursorWidth / 2,
        x: videoWrapperRect.width / 2 - cursorWidth / 2,
        duration: 0.6,
        ease: 'power2.out'
      }) : onMouseLeave()
    }, 0);
  }

  return (
    <>
      <Section {...attributes}>
        <VideoWrapper fromPost={props.fromPost}>
          <VideoItem ref={videoWrapper} iframeWidth={iframeWidth} iframeHeight={iframeHeight}>
            <Image alpha={imageAlpha} ref={image}>{props.image &&
              <>
                <Cursor ref={cursor}>Lecture</Cursor>
                <Img fixed={ props.image.sourceUrlSharp.childImageSharp.fixed } alt={ props.image.altText } />
              </>
            }</Image>
            <div id="iframe-cover"></div>
          </VideoItem>
        </VideoWrapper>
      </Section>
    </>
  )
}

export default Video
