import React from 'react'
import styled from 'styled-components'
import { useRelative } from '../hooks/useRelative'
import { Link } from 'gatsby'

const BannerButtonStyled = styled(Link)`
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%; 
  background-color: ${props => props.mode === 'green' ? props.theme.colors.green : props.theme.colors.grayDark};
  color: white;
  text-align: center;
  text-transform: uppercase;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 16px;
  line-height: 20px;

  &:hover {
    span {
      opacity: 0;
      transform: translateY(-10px);
      transition:
        transform 0.6s ${props => props.theme.eases.out},
        opacity 0.25s ${props => props.theme.eases.out};
    }

    &::before {
      transform: translateY(0);
      opacity: 1;
      transition:
        transform 0.6s ${props => props.theme.eases.out},
        opacity 0.4s ${props => props.theme.eases.out};
    }

    &::after { transform: scaleY(1); }
  }

  span {
    display: inline-block;
    color: ${props => props.mode === 'green' ? 'white' : 'black'};
    height: 100%;
    transition:
      transform 0.6s ${props => props.theme.eases.out},
      opacity 0.4s ${props => props.theme.eases.out};
  }

  &::before {
    content: '${props => props.text}';
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translateY(30px);
    transition:
      transform 0.6s ${props => props.theme.eases.out},
      opacity 0.25s ${props => props.theme.eases.out};
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.colors.greenLight};
    transform-origin: bottom center;
    transform: scaleY(0);
    transition: transform 0.6s ${props => props.theme.eases.out};
  }
`

const BannerButton = (props) => {
  const link = useRelative(props.url)

  return (
    <BannerButtonStyled mode={props.mode} to={link} text={props.title}><span>{props.title}</span></BannerButtonStyled>
  )
}

export default BannerButton