import React from 'react'
import styled from 'styled-components'
import Text from '../text'

const StyledText = styled(Text)`
  display: inline-block;
  position: relative;
  width: 100%;
  text-transform: uppercase;
  padding-bottom: 10px;
  color: ${props => props.theme.colors.green};
  margin-bottom: 30px;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.green};
  }
`

const Suptitle = (props) => <StyledText tag='div' size='12'>{props.children}</StyledText>

export default Suptitle