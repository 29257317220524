import React from 'react'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { Container, Row, Col } from 'react-grid-system'
import Suptitle from '../suptitle'
import Text from '../text'

const Section = styled.section`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 25px 0 50px 0;

  ${up('md')} { margin: 50px 0 100px 0; }
`

const Item = styled.a`
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 5px 0;

  font-family: ${props => props.theme.fonts.headings};
  font-style: normal;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;

  p { margin: 0; }

  ${up('sm')} { padding: 15px 0; }
  ${up('md')} { padding: 30px 0; }

  color: ${props => props.theme.colors.green};
  transition: color 0.4s ${props => props.theme.eases.out};

  body:not(.isMobile) & {
    @supports (-webkit-text-stroke: 1.5px ${props => props.theme.colors.green}) {
      color: transparent;
      -webkit-text-stroke: 1.5px ${props => props.theme.colors.green};
    }
  }

  body:not(.isMobile) &:hover {
    color: ${props => props.theme.colors.green};
  }

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.colors.green};
    transform: none;
  }
`

const Contact = (props) => (
  <Section data-scroll-section>
    <Container fluid>
      <Row>
        <Col lg={20} offset={{ lg: 2 }} style={{ marginBottom: '-30px' }}>
          <Suptitle>{props.title}</Suptitle>
        </Col>
        {props.listLinks && props.listLinks.map((item, index) => (
          <Col lg={20} offset={{ lg: 2 }} key={index}>
            <Item target='_blank' rel="noreferrer" href={item.link}><Text tag='span' size='100' dangerouslySetInnerHTML={{__html: item.content }}/></Item>
          </Col>
        ))}
      </Row>
    </Container>
  </Section>
)

export default Contact
