import React, { useEffect, useRef } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { Container, Row, Col } from 'react-grid-system'
import Text from '../text'
import SlideManager from 'slidemanager'
import Pager from '../pager'
import gsap from 'gsap'

const Section = styled.section`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 25px 0;

  ${up('md')} { margin: 50px 0; }
`

const Pagers = styled.div`
  margin-top: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BlocSlider = (props) => {
  let slider = null
  let sliderWidth = null

  const pagerLeft = useRef(false)
  const pagerRight = useRef(false)
  const slidesContents = useRef([props.slides.length])
  const slidesImages = useRef([props.slides.length])

  const onChangeSlide = (event) => {
    const tl = new gsap.timeline()

    tl.addLabel('start', 0)

    tl.to(slidesImages.current, {
      x: `${-sliderWidth * event.current}vw`,
      duration: 0.7,
      ease: 'power2.out'
    }, 'start')

    tl.to(slidesContents.current, {
      x: `${-sliderWidth * event.current}vw`,
      duration: 0.75,
      ease: 'power2.out'
    }, 'start')

    slider.done()
  }

  const createSlider = () => {
    slider = new SlideManager({
      length: props.slides.length,
      loop: false,
      auto: false,
      interval: 5,
      callback: onChangeSlide
    })
  }

  const setSizes = () => {
    sliderWidth = (100/24*14) + 4.16

    if (window.innerWidth < 1200 && window.innerWidth >= 1024) { sliderWidth = (100/24*18) + 4.16 }
    if (window.innerWidth < 1024 && window.innerWidth >= 768) { sliderWidth = (100/24*20) + 4.16 }
    else if (window.innerWidth < 768) { sliderWidth = 100 }

    for (let i = 0; i < slidesContents.current.length; i++) {
      slidesContents.current[i].style.marginTop = `-${slidesContents.current[i].offsetHeight / 2}px`
    }
  }


  useEffect(() => {
    setSizes()
    createSlider()

    window.addEventListener('resize', setSizes)
    pagerLeft.current && pagerLeft.current.addEventListener('click', slider.prev.bind(slider))
    pagerRight.current && pagerRight.current.addEventListener('click', slider.next.bind(slider))

    return () => { window.removeEventListener('resize', setSizes) }
  }, [])

  return (
    <Section data-scroll-section>
      <Container fluid>
        <Row nowrap>
          {props.slides && props.slides.map((slide, index) => (
            <Col key={index} sm={20} md={18} lg={14} offset={{ sm: index > 0 ? 1 : 2, md: index > 0 ? 1 : 3, lg: index > 0 ? 1 : 5 }}>
              <SlideItem {...slide} index={index} slidesImages={slidesImages} slidesContents={slidesContents}  />
            </Col>
          ))}
        </Row>
        <Pagers>
          <Pager ref={pagerLeft} left border></Pager>
          <Pager ref={pagerRight} right border></Pager>
        </Pagers>
      </Container>
    </Section>
  )
}

const Slide = styled.div`
  position: relative;
`

const SlideImage = styled.div`
  width: 100%;
  height: 350px;

  ${up('sm')} {  height: 450px; }
  ${up('xxl')} {  height: 600px; }
`

const SlideContent = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.green};
  width: 83.33vw;
  margin: 0 auto;
  padding: 30px 8.33vw;

  ${up('sm')} {
    padding: 60px 8.33vw;
    width: 58.33vw;
  }

  ${up('lg')} { width: 50vw; }
  ${up('xl')} { width: 41.6vw; }
`

const SlideTitle = styled(Text)`
  position: relative;
  text-transform: uppercase;
  color: white;
  margin-bottom: 20px;
`

const SlideText = styled(Text)`
  position: relative;
  color: white;
`

const SlideItem = (props) => {
  return (
    <Slide>
      <SlideImage ref={el => props.slidesImages.current[props.index] = el}>
        {props.image && <Img fixed={props.image.sourceUrlSharp.childImageSharp.fixed} alt={props.image.altText} />}
      </SlideImage>
      <SlideContent ref={el => props.slidesContents.current[props.index] = el}>
        <SlideTitle tag='div' size='18'>{props.title}</SlideTitle>
        <SlideText tag='div' size='12' dangerouslySetInnerHTML={{__html: props.content}} />
      </SlideContent>
    </Slide>
  )
}

export default BlocSlider
