import React from 'react'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'

const CoreImageWrapper = styled.div`
  .wp-block-image {
    display: flex;
    max-width: 100%;
    height: 100%;
    margin: 0;

    figure {
      height: 100%;
      margin: 0;

      &.aligncenter {
        margin-right: auto;
        margin-left: auto;
        margin-top: 0;
        margin-bottom: 0;
      }

      &.alignleft {
        margin-right: auto;
        margin-top: 0;
        margin-bottom: 0;
      }

      &.alignright {
        margin-left: auto;
        margin-top: 0;
        margin-bottom: 0;
      }

      img {
        ${down('sm')} {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`

const CoreImage = (data) => {

  return (
    <CoreImageWrapper>
      <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
    </CoreImageWrapper>
  )
}

export default CoreImage