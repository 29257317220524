import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { Container, Row, Col } from 'react-grid-system'
import Text from '../text'

const Section = styled.section`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 25px 0 50px 0;

  ${up('md')} { margin: 50px 0 100px 0; }
`

const KeyNumber = styled(Text)`
  white-space: nowrap;
  text-transform: uppercase;
  color: ${props => props.theme.colors.green};
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  
  ${up('sm')} { text-align: right; }

  ${up('lg')} {
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
  }
`

const KeyText = styled(Text)`
  color: ${props => props.theme.colors.yellow};
`

const RowNumber = styled(Row)`
  ${up('lg')} {
    flex-direction: ${props => props.direction};
  }
`

const ColNumber = styled(Col)`
  ${up('lg')} {
    margin-left: ${props => props.index * 4.16}vw !important;
    margin-right: ${props => props.index % 2 !== 0 ? `8.33vw`: `0`};
  }
`

const KeyNumbers = (data) => {
  const [numbers, setNumbers] = useState([])

  useEffect(() => {
    const numbers = []

    data.chiffres.map((chiffre) => {
      const number = chiffre.number.toString()
      const aNumbers = []

      for (let i = 0; i < number.length; i++) aNumbers.push(number[i])

      numbers.push(aNumbers)
    })

    setNumbers(numbers)
  }, [])
  return (
    <Section data-scroll-section>
      <Container fluid>
        {data.chiffres.map((chiffre, index) => {
          const flexDirection = index % 2 === 0 ? 'row' : 'row-reverse'

          return (
            <div key={index}>
              <RowNumber align='center' direction={flexDirection}>
                <ColNumber index={index} xs={10} lg="content">
                  <KeyNumber tag='div' size='500' data-scroll>
                  {numbers.length && numbers[index].map((number, index2) => (
                    <div style={{ display: "inline-block" }} key={index2} data-scroll data-scroll-delay={ 0.15 - (index2 + 1) * 0.04 } data-scroll-speed='2'>{number}</div>
                  ))}</KeyNumber>
                  </ColNumber>
                <Col sm={10} lg={9} xl={8} xxl={6} offset={{md: 1}}><KeyText tag='div' size='40key'>{chiffre.text}</KeyText></Col>
              </RowNumber>
            </div>
            )
          }
        )}
      </Container>
    </Section>
  )
}

export default KeyNumbers