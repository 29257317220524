import React, { useEffect, useRef } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { Container, Row, Col, Hidden } from 'react-grid-system'
import Text from '../text'
import ClassicContent from '../classic-content'
import SlideManager from 'slidemanager'
import gsap from 'gsap'
import Pager from '../pager'

const Section = styled.section`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 25px 0;

  ${up('md')} { margin: 50px 0; }

  ${up('lg')} { padding-top: 100px; }
`

const Slide = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin-top: 50px;

  ${up('lg')} {
    margin-top: 0;
  }
`

const SlideImage = styled.div`
  position: relative;
  left: -15px;
  width: calc(100% + 30px);
  height: 300px;

  ${props => !props.show && `
    opacity: 0;
    pointer-events: none;
  `};

  ${up('lg')} { height: 600px; }
`

const Number = styled.div`
  pointer-events: none;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 250px;
  line-height: 250px;
  color: ${props => props.theme.colors.green};
  transform: translateY(-50%);

  ${props => !props.show && `
    opacity: 0;
    pointer-events: none;
  `};

  ${up('lg')} {
    font-size: 500px;
    line-height: 500px;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }
`

const Pagers = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  position: absolute;
  left: -5px;
  top: 375px;

  ${up('lg')} {
    top: 0;
    left: 59%;
  }
`

const SlideContent = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: 60px;

  ${props => !props.show && `
    opacity: 0;
    pointer-events: none;
  `};
`

const SliderWrapper = styled.div`
  position: relative;
`

const Title = styled(Text)`
  z-index: 2;
  color: white;
  overflow: hidden;
  padding-bottom: 50px;

  ${up('sm')} {
    width: calc(100% - 125px);
    margin-left: 125px;
    padding-bottom: 0;
  }

  ${up('lg')} {
    position: absolute;
    left: 0;
    top: 0;
    width: 33.33vw;
    margin-left: 8.33vw;
    padding-bottom: 0;
  }

  span {
    will-change: transform;
    backface-visibility: hidden;
    position: relative;
    display: block;
  }

  & > span {
    transform: translateY(-100px);
  }
`

const TitleYellow = styled(Title)`
  z-index: 1;
  color: ${props => props.theme.colors.yellow};

  ${up('lg')} {
    top: -100px;
  }

  & > span {
    transform: translateY(0);
  }
`

const NumberSlider = (props) => {
  let slider = null

  const pagerLeft = useRef(false)
  const pagerRight = useRef(false)
  const slidesWrapper = useRef(null)
  const slidesNumbers = useRef([props.slides.length])
  const slidesContents = useRef([props.slides.length])
  const slidesImages = useRef([props.slides.length])

  const onChangeSlide = (event) => {
    gsap.to([slidesImages.current[event.previous], slidesContents.current[event.previous], slidesNumbers.current[event.previous]], {
      alpha: 0,
      duration: 0.5,
      ease: 'power2.out'
    })
 
    gsap.to([slidesImages.current[event.current], slidesContents.current[event.current], slidesNumbers.current[event.current]], {
      alpha: 1,
      duration: 0.5,
      ease: 'power2.out',
      onComplete: slider.done.bind(slider)
    })
  }

  const setSizes = () => {
    requestAnimationFrame(() => {
      let maxHeight = 0

      slidesContents.current.map((el) => { maxHeight = maxHeight > el.offsetHeight ? maxHeight : el.offsetHeight })

      if (window.innerWidth < 1200) maxHeight += 360
      else if (window.innerWidth > 1200 && maxHeight < 600) maxHeight = 600

      slidesWrapper.current.style.height = `${maxHeight}px`
    })
  } 

  const createSlider = () => {
    slider = new SlideManager({
      length: slidesContents.current.length,
      loop: false,
      auto: false,
      interval: 5,
      callback: onChangeSlide
    })
  }

  useEffect(() => {
    setSizes()
    createSlider()

    window.addEventListener('resize', setSizes)
    pagerLeft.current && pagerLeft.current.addEventListener('click', slider.prev.bind(slider))
    pagerRight.current && pagerRight.current.addEventListener('click', slider.next.bind(slider))

    return () => { window.removeEventListener('resize', setSizes) }
  }, [])

  return (
    <Section data-scroll-section>
      <Container fluid>
        <Hidden xs sm md><Title tag='div' size='72'><span><span data-scroll data-scroll-speed='-0.3'>{props.title}</span></span></Title></Hidden>
        <TitleYellow tag='div' size='72'><span><span data-scroll data-scroll-speed='-0.3'>{props.title}</span></span></TitleYellow>
        <SliderWrapper ref={slidesWrapper}>
        <Pagers>
          <Pager ref={pagerLeft} left border></Pager>
          <Pager ref={pagerRight} right border></Pager>
        </Pagers>
        {props.slides.map((slide, index) => (
          <Slide key={index}>
            <Number show={index === 0} ref={el => slidesNumbers.current[index] = el}>{index + 1}</Number>
            <Row>
              <Col lg={12}>
                <SlideImage show={index === 0} ref={el => slidesImages.current[index] = el}>
                  {slide.image && <Img fixed={ slide.image.sourceUrlSharp.childImageSharp.fixed } alt={ slide.image.altText } />}
                </SlideImage>
              </Col>
              <Col lg={8} offset={{ lg: 3 }}>
                <SlideContent show={index === 0} ref={el => slidesContents.current[index] = el}>
                  <ClassicContent props={slide}/>
                </SlideContent>
              </Col>
            </Row>
          </Slide>
        ))}
        </SliderWrapper>
      </Container>
    </Section>
  )
}

export default NumberSlider
